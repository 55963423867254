$beer: #f6c13b;

* {
  box-sizing: border-box;
}

.app {
  margin: 100px auto;
  width: 900px;
  max-width: 90%;
}

.app__heading {
  text-align: center;
  flex: 1 1 auto;
}

.app__header {
  display: flex;
  align-items: center;
}

.app__action {
  font-size: 1.5rem;
}

.app__calendar {
  display: flex;
  flex-wrap: wrap;
}

.app__month {
  flex: 1 1 auto;
  width: 33%;
  padding: 1rem;
  min-width: 300px;
}

.app__month-name {
  text-align: center;
}

.app__days {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;

  > * {
    width: 1 / 7 * 100%;
    margin-bottom: .75rem;
  }
}

.app__day {
  display: block;
  padding: .5rem .25rem;
  text-align: center;
  font-weight: bold;
  border-radius: 100%;
  color: black;
  text-decoration: none;
  position: relative;

  &:hover {
    background-color: rgba($beer, 0.5);
  }
}

.app__day--future {
  opacity: .25;
}

.app__day--drunk {
  --fa-secondary-color: #{$beer};
  --fa-secondary-opacity: 1.0;
}

.app__drunk-icon {
  position: absolute;
  bottom: 0;
  margin-left: .25rem;
}
